import { Link } from '@inertiajs/react';
import { Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV, FaPen } from 'react-icons/fa';

import type { Provider } from '@/types';

interface Props {
  provider: Provider;
}

export default function KebabMenu(props: Props) {
  const { t } = useTranslation();
  const { provider } = props;
  const { id, first_name, status } = provider;

  return (
    <Menu>
      <Menu.Target>
        <span>
          <FaEllipsisV />
        </span>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<FaPen />}
          component={Link}
          href={`/provider/providers/${id}/edit`}
        >
          {t('common.action.edit')}
        </Menu.Item>
        <Menu.Item component={Link} href={`/provider/providers/${provider.id}`}>
          {t(`common.action.show`)}
        </Menu.Item>
        {provider.status === 'active' && (
          <Menu.Item
            component={Link}
            method="delete"
            as="button"
            href={`/provider/providers/${id}/deactivate`}
            onClick={e => {
              if (
                !confirm(
                  t('provider.message.deactivate_confirmation', {
                    name: first_name,
                  }),
                )
              ) {
                e.preventDefault();
              }
            }}
          >
            {t('provider.action.deactivate')}
          </Menu.Item>
        )}
        {status === 'inactive' && (
          <Menu.Item
            component={Link}
            method="put"
            as="button"
            href={`/provider/providers/${id}/activate`}
            onClick={e => {
              if (
                !confirm(
                  t('provider.message.activate_confirmation', {
                    name: first_name,
                  }),
                )
              ) {
                e.preventDefault();
              }
            }}
          >
            {t('provider.action.activate')}
          </Menu.Item>
        )}
        <Menu.Item
          component={Link}
          method="delete"
          as="button"
          href={`/provider/providers/${id}`}
          onClick={e => {
            if (
              !confirm(
                t('provider.message.delete_confirmation', {
                  name: first_name,
                }),
              )
            ) {
              e.preventDefault();
            }
          }}
        >
          {t('common.action.delete')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
