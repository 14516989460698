import { Page } from '@/components';
import type { ProductBundle, Profile, Vendor } from '@/types';

import { Form } from './_Form';

interface Props {
  product_bundle: ProductBundle;
  profiles: Profile[];
  sample_statuses: string[];
  vendors: Vendor[];
}

export default function Edit(props: Props) {
  return (
    <Page title="Edit Product Bundle">
      <Form
        productBundle={props.product_bundle}
        profiles={props.profiles}
        sampleStatuses={props.sample_statuses}
        vendors={props.vendors}
      />
    </Page>
  );
}
