import { Modal, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  opened: boolean;
  close: () => void;
}

export default function WeightExplanationModal({ opened, close }: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      title={<Title order={4}>{t('sample_concentration.modal_header')}</Title>}
      onClose={close}
      centered
      size="md"
    >
      <Text>{t('sample_concentration.modal_text')}</Text>
    </Modal>
  );
}
