import type { SampleConcentration } from '@/types';

export const getWeight = (concentration: SampleConcentration) => {
  if (concentration === null) return 0;

  const { color, weight } = concentration;

  if (color === 'green') return 0;
  if (color === 'yellow') return 0.5;

  if (color === 'red' && (weight === 0 || weight === null)) return 1;

  return weight;
};
