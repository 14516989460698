import { useForm } from '@inertiajs/react';
import { Button, Card, Group, Select, TextInput } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Profile, Vendor } from '@/types';

interface Props {
  profile: Profile;
  vendor: Vendor[];
}

export function Form({ profile, vendor }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors } = useForm(profile);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      profile.id != null ? 'patch' : 'post',
      `/admin/profiles/${profile.id ?? ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('profile.column.name')} required>
            <TextInput
              required
              value={data.name}
              onChange={e => {
                setData('name', e.target.value);
              }}
              error={errors.name}
              placeholder="Metabolomics"
            />
          </Field>
          <Field label={t('profile.column.default_sample_type')} required>
            <TextInput
              required
              value={data.sample_type}
              onChange={e => {
                setData('sample_type', e.target.value);
              }}
              error={errors.sample_type}
              placeholder="Plasma"
            />
          </Field>
          <Field label={t('profile.column.default_vendor')} required>
            <Select
              value={data.vendor_id?.toString()}
              onChange={value => {
                setData('vendor_id', Number(value));
              }}
              data={vendor.map(value => ({
                value: value.id.toString(),
                label: value.name,
              }))}
              error={errors.vendor}
            />
          </Field>
          <Field label={t('profile.column.default_vendor_assay_id')} required>
            <TextInput
              required
              value={data.vendor_assay_id}
              onChange={e => {
                setData('vendor_assay_id', e.target.value);
              }}
              error={errors.vendor_assay_id}
              placeholder="MYCO 1.2"
            />
          </Field>
          <Field label={t('profile.column.all_vendor_assay_ids')}>
            <TextInput
              value={data.vendor_assay_id_list}
              onChange={e => {
                setData('vendor_assay_id_list', e.target.value);
              }}
              error={errors.vendor_assay_id_list}
              placeholder="MYCO 1.0, MYCO 1.1, MYCO 1.2"
            />
          </Field>
          <Field label={t('profile.column.default_amount')} required>
            <TextInput
              required
              value={data.amount}
              onChange={e => {
                setData('amount', e.target.value);
              }}
              error={errors.amount}
            />
          </Field>
          <Field label={t('profile.column.default_amount_unit')} required>
            <TextInput
              required
              value={data.amount_unit}
              onChange={e => {
                setData('amount_unit', e.target.value);
              }}
              error={errors.amount_unit}
              placeholder="ml"
            />
          </Field>
          <Field label={t('profile.column.default_container')} required>
            <TextInput
              required
              value={data.container}
              onChange={e => {
                setData('container', e.target.value);
              }}
              error={errors.container}
              placeholder="cryovial"
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
