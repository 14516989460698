import {
  Box,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';

import {
  HealthAreaHistory,
  HealthAreaRisk,
  SampleConcentrationLegend,
} from '@/charts';
import SampleConcentrationList from '@/charts/SampleConcentrationList';
import { Back, Reveal } from '@/components';
import { client_reports_health_data_path } from '@/routes';
import type { HealthArea, History, SampleConcentration } from '@/types';

import WeightExplanationModal from './_WeightExplanationModal';

interface Props {
  health_report: HealthArea;
  sample_concentrations: SampleConcentration[];
  health_area_history: History[];
}

export default function Show({
  health_report: healthReport,
  sample_concentrations: sampleConcentrations,
  health_area_history: healthAreaHistory,
}: Props) {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Container size="sm">
      <Stack gap="xl">
        <Stack gap="xl">
          <Group gap="xs">
            <Back
              label={healthReport.name}
              to={client_reports_health_data_path()}
            />
          </Group>
          <Card p="xl" style={{ overflow: 'visible' }}>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 7 }}>
                <Stack>
                  <Title order={2} size="h4">
                    Overview
                  </Title>
                  <Box hiddenFrom="md" h={200}>
                    {healthAreaHistory.length > 1 ? (
                      <HealthAreaHistory
                        data={healthAreaHistory.toReversed()}
                      />
                    ) : (
                      healthAreaHistory.map(row => (
                        <HealthAreaRisk
                          name={healthReport.name}
                          risk={row.percentage_risk}
                          color={row.color}
                        />
                      ))
                    )}
                  </Box>
                  <Reveal text={healthReport.description} delimiter="↵↵" />
                </Stack>
              </Grid.Col>
              <Grid.Col span={5} visibleFrom="md">
                <Box h={200}>
                  {healthAreaHistory.length > 1 ? (
                    <HealthAreaHistory data={healthAreaHistory.toReversed()} />
                  ) : (
                    healthAreaHistory.map(row => (
                      <HealthAreaRisk
                        name={healthReport.name}
                        risk={row.percentage_risk}
                        color={row.color}
                      />
                    ))
                  )}
                </Box>
              </Grid.Col>
            </Grid>
          </Card>
        </Stack>
        <Stack></Stack>
        <Stack gap="lg">
          <Title size="h3">
            {`${healthReport.name} ${t('biomarker.plural').toLowerCase()} (${sampleConcentrations.length})`}
          </Title>
          <SampleConcentrationLegend />
          <Grid>
            <Grid.Col span={8} />
            <Grid.Col span={4} style={{ flex: 'row' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Flex justify="flex-start" direction="row" wrap="wrap">
                  <Text pr="sm">{t('sample_concentration.weight_header')}</Text>
                  <Text>
                    <FiInfo
                      size={24}
                      color="#3264d6"
                      style={{ cursor: 'pointer' }}
                      onClick={open}
                    />
                  </Text>
                </Flex>
              </Box>
            </Grid.Col>
          </Grid>
          <SampleConcentrationList
            sample_concentrations={sampleConcentrations}
            health_report={healthReport}
          />
        </Stack>
      </Stack>
      <WeightExplanationModal opened={opened} close={close} />
    </Container>
  );
}
