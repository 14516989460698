import { Button, Checkbox, Group, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleLine, RiErrorWarningLine } from 'react-icons/ri';

import { Field } from '@/components';

interface Props {
  consented: boolean;
  authorized: boolean;
  onConsentChange: (consent: boolean) => void;
  onAuthorizationChange: (authorization: boolean) => void;
}

export function Consent({
  consented,
  authorized,
  onConsentChange,
  onAuthorizationChange,
}: Props) {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Field label="Consent and authorization" required>
        <Button
          leftSection={
            !consented || !authorized ? (
              <RiErrorWarningLine />
            ) : (
              <RiCheckboxCircleLine />
            )
          }
          color={!consented || !authorized ? 'red' : 'green'}
          variant="subtle"
          size="sm"
          onClick={open}
        >
          Review consent and authorization{' '}
          {(!consented || !authorized) && 'before continuing'}
        </Button>
      </Field>

      <Modal opened={opened} onClose={close} size="xl">
        <Stack p="xl">
          <h3>{t('provider.consent.title')}</h3>
          <Checkbox
            size="md"
            label={t('provider.consent.consented.label')}
            description={
              <>
                {(
                  t('provider.consent.consented.description', {
                    returnObjects: true,
                  }) as string[]
                ).map((note, index) => (
                  <Text size="md" key={index}>
                    {index === 0 ? note : `- ${note}`}
                  </Text>
                ))}
              </>
            }
            checked={consented}
            onChange={e => onConsentChange(e.currentTarget.checked)}
          />
          <Checkbox
            size="md"
            label={t('provider.consent.authorized.label')}
            description={
              <>
                {(
                  t('provider.consent.authorized.description', {
                    returnObjects: true,
                  }) as string[]
                ).map((line, index) => (
                  <Text size="md" key={index}>
                    {`- ${line}`}
                  </Text>
                ))}
              </>
            }
            checked={authorized}
            onChange={e => onAuthorizationChange(e.currentTarget.checked)}
          />
          <Stack gap="xs">
            <Text size="sm">{t('provider.consent.note.title')}</Text>
            {(
              t('provider.consent.note.notes', {
                returnObjects: true,
              }) as string[]
            ).map((note, index) => (
              <Text size="sm" key={index}>
                {note}
              </Text>
            ))}
          </Stack>
        </Stack>
        <Group justify="end" p="md">
          <Button onClick={close}>{t('common.action.confirm')}</Button>
        </Group>
      </Modal>
    </>
  );
}
