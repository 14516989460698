import { Link } from '@inertiajs/react';
import { Badge, Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { Profile, Vendor } from '@/types';

interface Props {
  profiles: (Profile & {
    vendor: Vendor;
  })[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['name', 'sample_type'];

  return (
    <Page
      title="Profile"
      actions={
        <Button component={Link} href="/admin/profiles/new">
          Create Profile
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.profiles}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
              },
              {
                header: t('profile.column.name'),
                accessorFn: row => row.name,
              },
              {
                header: t('profile.column.default_sample_type'),
                accessorFn: row => row.sample_type,
              },
              {
                header: t('profile.column.default_vendor'),
                accessorFn: row => row.vendor.name,
              },
              {
                header: t('profile.column.default_vendor_assay_id'),
                cell: ({ row }) => (
                  <Badge color="teal">{row.original.vendor_assay_id}</Badge>
                ),
              },
              {
                header: t('profile.column.all_vendor_assay_ids'),
                cell: ({ row }) =>
                  row.original.vendor_assay_id_list?.map((assayId: string) => (
                    <Badge color="grey" key={assayId}>
                      {assayId}
                    </Badge>
                  )),
              },
              {
                header: t('profile.column.default_amount'),
                accessorFn: row => row.amount,
              },
              {
                header: t('profile.column.default_amount_unit'),
                accessorFn: row => row.amount_unit,
              },
              {
                header: t('profile.column.default_container'),
                accessorFn: row => row.container,
              },

              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/profiles/${row.original.id}`}
                      >
                        {t(`common.action.show`)}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        href={`/admin/profiles/${row.original.id}/edit`}
                      >
                        {t(`common.action.edit`)}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        method="delete"
                        as="button"
                        href={`/admin/profiles/${row.original.id}`}
                      >
                        {t('common.action.delete')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
