import { Badge, Card, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Provider, Site } from '@/types';

interface Props {
  provider: Provider;
  sites: Site[];
}
export function Details({ provider, sites }: Props) {
  const providerSiteNames =
    provider.provider_sites
      ?.map((providerSite: { site_id: number }) => {
        const site = sites.find(site => site.id === providerSite.site_id);
        return site ? site.description : null;
      })
      .filter(Boolean) || [];

  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section>
        <Field label={t('site.plural')}>
          <Text>{providerSiteNames.join(', ')}</Text>
        </Field>
        <Field label={t('user.column.name')}>
          {provider.first_name} {provider.last_name}
        </Field>
        <Field label={t('user.column.email')}>{provider.email}</Field>
        <Field label={t('address.column.full_address')}>
          {provider.address?.full_address}
        </Field>
        <Field label={t('user.column.role')}>{provider.role}</Field>
        <Field label={t('user.column.status')}>
          <Badge
            color={provider.invitation_accepted_at !== null ? 'green' : 'red'}
          >
            {provider.invitation_accepted_at !== null
              ? t('user.column.invitation_accepted')
              : t('user.column.invitation_unaccepted')}
          </Badge>
        </Field>
      </Card.Section>
    </Card>
  );
}
