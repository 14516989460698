import { useForm } from '@inertiajs/react';
import { Button, Card, Group, TextInput } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type {
  ProductBundle,
  ProductSampleTemplate,
  Profile,
  Vendor,
} from '@/types';
import { nestedAttributes } from '@/utils';

import { ProductSampleTemplates } from './_ProductSampleTemplates';

interface Props {
  productBundle: ProductBundle & {
    product_sample_templates?: ProductSampleTemplate[];
  };
  profiles: Profile[];
  sampleStatuses: string[];
  vendors: Vendor[];
}

export function Form({
  productBundle,
  profiles,
  sampleStatuses,
  vendors,
}: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors, transform } = useForm(productBundle);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      productBundle.id != null ? 'patch' : 'post', //
      `/admin/product_bundles/${productBundle.id ?? ''}`,
    );
  };

  // Rename `product_sample_templates` to `product_sample_templates_attributes` before submitting.
  transform(nestedAttributes(['product_sample_templates']));

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('product_bundle.column.product_name')} required>
            <TextInput
              name="product_name"
              value={data.product_name}
              onChange={e => {
                setData('product_name', e.target.value);
              }}
              error={errors.product_name}
              required
            />
          </Field>
          <Field label={t('product_bundle.column.description')} required>
            <TextInput
              name="description"
              value={data.description}
              onChange={e => {
                setData('description', e.target.value);
              }}
              error={errors.description}
              required
            />
          </Field>
          <Field label={t('product_bundle.column.sub_description')}>
            <TextInput
              name="sub_description"
              value={data.sub_description}
              onChange={e => {
                setData('sub_description', e.target.value);
              }}
              error={errors.sub_description}
            />
          </Field>
          <Field label={t('product_bundle.column.default_price_in_cents')}>
            <TextInput
              name="default_price_in_cents"
              value={data.default_price_in_cents}
              onChange={e => {
                setData('default_price_in_cents', parseInt(e.target.value));
              }}
              error={errors.default_price_in_cents}
            />
          </Field>
        </Card.Section>
      </Card>
      <ProductSampleTemplates
        profiles={profiles}
        sampleStatuses={sampleStatuses}
        vendors={vendors}
        errors={errors}
        value={data.product_sample_templates ?? []}
        onChange={value => {
          setData('product_sample_templates', value);
        }}
      />
      <Group justify="end" p="md">
        <Button type="submit">{t('common.action.save')}</Button>
      </Group>
    </form>
  );
}
