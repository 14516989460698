import { Badge, Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Profile, Vendor } from '@/types';

interface Props {
  profile: Profile & {
    vendor: Vendor;
  };
}

export function Details({ profile }: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Section>
        <Field label={t('profile.column.name')}>{profile.name}</Field>
        <Field label={t('profile.column.default_sample_type')}>
          {profile.sample_type}
        </Field>
        <Field label={t('profile.column.default_vendor')}>
          {profile.vendor.name}
        </Field>
        <Field label={t('profile.column.default_vendor_assay_id')}>
          <Badge color="teal">{profile.vendor_assay_id}</Badge>
        </Field>
        <Field label={t('profile.column.all_vendor_assay_ids')}>
          {profile.vendor_assay_id_list?.map((assayId: string) => (
            <div key={assayId}>{assayId}</div>
          ))}
        </Field>
        <Field label={t('profile.column.default_amount')}>
          {profile.amount}
        </Field>
        <Field label={t('profile.column.default_amount_unit')}>
          {profile.amount_unit}
        </Field>
        <Field label={t('profile.column.default_container')}>
          {profile.container}
        </Field>
      </Card.Section>
    </Card>
  );
}
