import { Grid, Text } from '@mantine/core';
import { type ReactElement, type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getHealthAreas } from '@/api/provider';
import { useUser } from '@/hooks';
import { Provider as Layout } from '@/layouts';
import type { Client, Provider } from '@/types';

import { SubHeader } from '../_SubHeader';
import { Content } from './_Content';
import { NavGroup } from './_NavGroup';

interface Props {
  client: Client & {
    current_report?: {
      id: number;
    };
    assays: {
      id: number;
      report?: {
        id: number;
      };
    }[];
  };
}

function HealthScores({ client }: Props) {
  const { t } = useTranslation();
  const provider = useUser<Provider>();

  const reportCount = client.assays.filter(
    assay => assay.report && assay.report.id !== null,
  ).length;

  const [selectedHealthArea, setSelectedHealthArea] = useState(0);

  const { isLoading, data } = useQuery(
    ['HealthAreas'],
    async () =>
      await getHealthAreas(
        { clientId: client.id },
        {
          email: provider.email,
          apiKey: provider.api_key,
        },
      ),
    {
      initialData: () => [],
    },
  );

  if (isLoading) {
    return (
      <Text ta="center" p="xl">
        {t('common.loading')}
      </Text>
    );
  }

  if (client.current_report && data && data.length > 0) {
    return (
      <Grid gutter="xl">
        <Grid.Col span={{ base: 12, md: 3 }}>
          <NavGroup
            healthAreas={data}
            value={selectedHealthArea}
            onSelect={index => setSelectedHealthArea(index)}
            reportCount={reportCount}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 9 }}>
          <Content
            clientId={client.id}
            healthArea={data[selectedHealthArea]}
            reportId={client.current_report.id}
          />
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <Text p="xl" ta="center">
      No health scores
    </Text>
  );
}

HealthScores.layout = (page: ReactNode & { props: Props }): ReactElement => (
  <Layout subHeader={<SubHeader client={page.props.client} />} page={page} />
);

export default HealthScores;
