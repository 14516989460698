import { Link } from '@inertiajs/react';
import { Grid, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { RiArrowRightWideFill } from 'react-icons/ri';

import { BiomarkerWeightPills as Weight } from '@/charts/BiomarkerWeightPills';
import { CardWithTab } from '@/components';
import {
  client_reports_health_datum_path,
  client_reports_sample_concentration_path,
} from '@/routes';
import type { HealthArea, SampleConcentration } from '@/types';
import { getWeight } from '@/utils/getConcentrationWeight';

interface Props {
  health_report: HealthArea;
  sample_concentrations: SampleConcentration[];
}

export default function SampleConcentrationList({
  health_report: healthReport,
  sample_concentrations: sampleConcentrations,
}: Props) {
  const { t } = useTranslation();

  return (
    <Stack gap="xs">
      {sampleConcentrations.map(row => (
        <CardWithTab
          key={row.id}
          color={row.color}
          component={Link}
          href={client_reports_sample_concentration_path(row.id, {
            params: {
              back: client_reports_health_datum_path(healthReport.myco_id),
            },
          })}
        >
          <Grid justify="space-between">
            <Grid.Col span={8}>
              <Text>{row.measure_name}</Text>
            </Grid.Col>
            {row.indicated && (
              <Grid.Col span={3}>
                <Weight value={getWeight(row)} color={row.color} />
              </Grid.Col>
            )}
            <Grid.Col span={1} ta="end" style={{ lineHeight: 1.2 }}>
              <RiArrowRightWideFill size={24} color="#3264d6" />
            </Grid.Col>
          </Grid>
          {!row.indicated && row.color !== 'green' && (
            <Text size="xs" c="dimmed">
              {t('report.biomarker.low_high_warning', {
                level: row.level === 'low' ? 'high' : 'low',
              })}
            </Text>
          )}
        </CardWithTab>
      ))}
    </Stack>
  );
}
