import {
  Button,
  Card,
  Group,
  Select,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import type { ProductSampleTemplate, Profile, Vendor } from '@/types';

interface Props {
  profiles: Profile[];
  sampleStatuses: string[];
  vendors: Vendor[];
  errors: Record<string, string>;
  value: ProductSampleTemplate[];
  onChange: (value: ProductSampleTemplate[]) => void;
}

export function ProductSampleTemplates({
  profiles,
  sampleStatuses,
  vendors,
  errors,
  value = [],
  onChange,
}: Props) {
  const { t } = useTranslation();

  const add = () => {
    onChange([...value, {}]);
  };

  const update = (idx: number, key: string, nextValue) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, [key]: nextValue } : v)));
  };

  const retrieve_profile = (profileId: string) => {
    if (profileId) {
      return profiles.filter(
        profile => profile.id.toString() === profileId.toString(),
      )[0];
    }
  };

  const vendor_assay_ids = (profileId: string) => {
    if (profileId) {
      const profile = retrieve_profile(profileId);
      return profile.vendor_assay_id_list;
    }
  };

  // Autofill fields based on selected profile
  const updateProfile = (idx: number, profileId: string) => {
    const profile = retrieve_profile(profileId);

    onChange(
      value.map((v, i) =>
        i === idx
          ? {
              ...v,
              profile_id: profileId,
              sample_type: profile.sample_type,
              vendor_id: profile.vendor_id,
              vendor_assay_id: profile.vendor_assay_id,
            }
          : v,
      ),
    );
  };

  const remove = (idx: number) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, _destroy: true } : v)));
  };

  return (
    <Card mt="lg">
      <Card.Section withBorder>
        <Group p="md" justify="space-between">
          <Text>{t('product_sample_template.plural')}</Text>
          <Button
            size="xs"
            px="xs"
            onClick={() => {
              add();
            }}
          >
            <FaPlus />
          </Button>
        </Group>
        {errors.product_sample_templates && (
          <Text c="red" pl="md" pb="md">
            {errors.product_sample_templates.join(' and ')}
          </Text>
        )}
      </Card.Section>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t('profile.singular')}</Table.Th>
            <Table.Th>
              {t('product_sample_template.column.sample_type')}
            </Table.Th>
            <Table.Th>
              {t('product_sample_template.column.sample_status')}
            </Table.Th>
            <Table.Th>{t('product_sample_template.column.id_prefix')}</Table.Th>
            <Table.Th>{t('vendor.singular')}</Table.Th>
            <Table.Th>
              {t('product_sample_template.column.vendor_assay_id')}
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {value.map(
            (item, idx) =>
              !item._destroy && (
                <Table.Tr key={idx}>
                  <Table.Td valign="top">
                    <Select
                      value={item.profile_id?.toString()}
                      name={`profile_${idx}`}
                      error={errors?.[
                        `product_sample_templates[${idx}].profile`
                      ]?.join(' and ')}
                      onChange={value => {
                        updateProfile(idx, value as string);
                      }}
                      data={profiles.map(profile => ({
                        label: profile.name,
                        value: profile.id.toString(),
                      }))}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <Select
                      value={item.sample_type}
                      name={`sample_type_${idx}`}
                      error={errors?.[
                        `product_sample_templates[${idx}].sample_type`
                      ]?.join(' and ')}
                      onChange={value => {
                        update(idx, 'sample_type', value);
                      }}
                      data={['Serum', 'Plasma', 'Urine', 'Fecal', 'PAXgene']}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <Select
                      value={item.sample_status}
                      name={`sample_status_${idx}`}
                      error={errors?.[
                        `product_sample_templates[${idx}].sample_status`
                      ]?.join(' and ')}
                      onChange={value => {
                        update(idx, 'sample_status', value);
                      }}
                      data={sampleStatuses}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <TextInput
                      value={item.id_prefix ?? ''}
                      name={`id_prefix_${idx}`}
                      error={errors?.[
                        `product_sample_templates[${idx}].id_prefix`
                      ]?.join(' and ')}
                      onChange={e => {
                        update(idx, 'id_prefix', e.target.value);
                      }}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <Select
                      value={item.vendor_id?.toString()}
                      name={`vendor_${idx}`}
                      error={errors?.[
                        `product_sample_templates[${idx}].vendor`
                      ]?.join(' and ')}
                      onChange={value => {
                        update(idx, 'vendor_id', value);
                      }}
                      data={vendors.map(vendor => ({
                        label: vendor.name,
                        value: vendor.id.toString(),
                      }))}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <Select
                      value={item.vendor_assay_id}
                      name={`vendor_assay_id_${idx}`}
                      error={errors?.[
                        `product_sample_templates[${idx}].vendor_assay_id`
                      ]?.join(' and ')}
                      onChange={value => {
                        update(idx, 'vendor_assay_id', value);
                      }}
                      data={vendor_assay_ids(item.profile_id)}
                    />
                  </Table.Td>
                  {value.length > 1 && (
                    <Table.Td valign="top">
                      <UnstyledButton
                        mt="11"
                        type="button"
                        onClick={() => {
                          remove(idx);
                        }}
                      >
                        <FaTrashAlt />
                      </UnstyledButton>
                    </Table.Td>
                  )}
                </Table.Tr>
              ),
          )}
        </Table.Tbody>
      </Table>
    </Card>
  );
}
