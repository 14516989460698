import { Page } from '@/components';
import type { Address, Carmen, Company, Provider, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  provider: Provider;
  companies: Company[];
  sites: Site[];
  carmen: Carmen;
  address: Address;
  api_key: string;
}

export default function New({
  provider,
  companies,
  sites,
  carmen,
  address,
  api_key,
}: Props) {
  return (
    <Page title="Create Provider">
      <Form
        provider={provider}
        companies={companies}
        sites={sites}
        carmen={carmen}
        address={address}
        api_key={api_key}
      />
    </Page>
  );
}
