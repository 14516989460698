export const ADMIN_ROLE = ['editor', 'reviewer', 'super'];

export const ADMINISTRATOR_PRIVILEGE_SET = [
  'change_assays',
  'change_clients',
  'change_health_profiles',
  'change_providers',
  'change_reports',
  'change_site',
];

export const ADMIN_PRIVILEGE_SET = [
  'access_clients',
  'access_financials',
  'action_plan_review',
  'change_admins',
  'change_assays',
  'change_definitions',
  'change_foods',
  'change_measures',
  'change_products',
  'change_projects',
  'change_reporting_batch',
  'change_reports',
  'change_sites',
  'change_study_groups',
  'client_and_samples_only',
  'clinical_review',
  'preview_reports',
  'qa_review',
  'qc_review',
  'receive_vendor_portal_emails',
];

export const APP_GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.molecularyou.my_app&pli=1';

export const APP_APP_STORE_URL =
  'https://apps.apple.com/us/app/molecular-you/id1585027655';

export const APP_SHOP_URL = 'https://store.molecularyou.com';

export const APP_SUPPORT_EMAIL = 'support@molecularyou.com';

export const APP_SUPPORT_URL = 'https://support.molecularyou.com/hc/en-ca';
export const APP_SUPPORT_URL_RESULTS =
  'https://support.molecularyou.com/hc/en-ca/articles/9173023095693-When-will-I-get-my-results';

export const APP_CLIENT_ONBOARDING_TUTORIAL_URL =
  'https://molecularyouproviders.zendesk.com/hc/en-ca/articles/22957839916941-Molecular-You-Client-Onboarding-Tutorial';

export const BATCH_AMOUNT_UNIT = ['uL', 'mL', 'swab'];

export const BATCH_PROFILE = [
  'Metabolomics',
  'Proteomics',
  'Microbiomics',
  'Genetics',
  'Exposomics',
];

export const BATCH_VENDOR_ASSAY = [
  'MYCO 1.0',
  'MYCO 1.1',
  'MYCO 1.2',
  'Exposome 1.0',
  'Exposome 1.1',
  'MRM 1.1',
  'MRM-270',
  'Illumina 1.0',
  'Microbiome Insights 1.0',
  'Microbiome Insights 1.1',
  'Urine GC Organic Acid',
  'GSA 1.0',
  'GSA 2.0',
  'GSA 3.0',
];

export const COACH_PRIVILEGE_SET = ['change_health_profiles', 'change_reports'];

export const PROVIDER_PRIVILEGE_SET = [
  'change_assays',
  'change_clients',
  'change_health_profiles',
  'change_providers',
  'change_reports',
  'change_site',
];

export const PRACTITIONER_PRIVILEGE_SET = [
  'change_assays',
  'change_clients',
  'change_health_profiles',
  'change_providers',
  'change_reports',
  'change_site',
];

export const STAFF_PRIVILEGE_SET = ['change_assays'];

export const PROVIDER_ROLE = {
  administrator: ADMINISTRATOR_PRIVILEGE_SET,
  practitioner: PRACTITIONER_PRIVILEGE_SET,
};

export const SAMPLE_STATUS = [
  'Analysis',
  'Backup',
  'Compromised',
  'Destroyed',
  'Research',
  'To be destroyed',
  'To be rerun',
  'Used up',
];

export const SAMPLE_TYPE = [
  'Fecal',
  'PAXgene',
  'Plasma',
  'Serum (Metal Free)',
  'Serum',
  'Urine',
];

export const SAMPLE_PROFILE = [
  'Exposomics',
  'Genetics',
  'Metabolomics (Urine)',
  'Metabolomics',
  'Microbiomics',
  'Proteomics',
];

export const COLOR_SCHMES = ['auto', 'light', 'dark'];

export const SEX = ['female', 'male'];

export const SITE_TYPE = ['Clinic', 'Reseller', 'Internal', 'Basic'];

export const COUNTRY = ['CA', 'US'];

export const COLLECTION_SITE_BOOKING_PREFERENCE = [
  'Phone',
  'Drop-in',
  'Book Online',
];

export const COLLECTION_SITE_REQUISITION_FORM_SET = [
  'dynacare_requisition_form',
  'dynalife_requisition_form',
  'cls_requisition_form',
  'life_labs_ontario_requisition_form',
  'life_labs_saskatchewan_requisition_form',
  'life_labs_bc_requisition_form',
];

export const USER_STATUS = ['active', 'inactive'];

export const MAP_DEFAULT_CENTER = { lat: 49.2827, lng: -123.1207 };
