import { Link } from '@inertiajs/react';
import { Button } from '@mantine/core';

import { Page } from '@/components';
import type { Provider, Site } from '@/types';

import { Details } from './_Details';

interface Props {
  sites: Site[];
  provider: Provider;
}

export default function New(props: Props) {
  return (
    <Page
      title="Provider Details"
      actions={
        <Button
          component={Link}
          href={`/provider/providers/${props.provider.id as number}/edit`}
        >
          Edit
        </Button>
      }
    >
      <Details provider={props.provider} sites={props.sites} />
    </Page>
  );
}
