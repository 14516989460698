import { Box, Group } from '@mantine/core';

import type { Color } from '@/types';
import { getColor } from '@/utils';

interface Props {
  value: number;
  color: Color;
}

export function BiomarkerWeightPills({ value, color }: Props) {
  if (color === 'green' || !color) return;

  const MAX_WEIGHT = 6;

  // Determine fill percentage (0 to 1)
  const fractionalFill = (index: number) =>
    Math.max(0, Math.min(1, value - index));
  const getBorderRadius = (index: number) =>
    fractionalFill(index) === 1 ? '5px' : '5px 0 0 5px';

  return (
    <Group gap="xs" h={'100%'}>
      {Array.from({ length: MAX_WEIGHT }).map((_, index) => {
        return (
          <Box
            key={`pill-${index}`}
            style={{
              width: 20,
              height: 10,
              borderRadius: 5,
              backgroundColor: '#F6F8FD',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box // Inner filled portion
              style={{
                width: `${fractionalFill(index) * 100}%`, // Fill width based on fractional value
                height: '100%',
                backgroundColor: getColor(color),
                borderRadius: getBorderRadius(index), // Fully rounded if fully filled, else round only left side
              }}
            />
          </Box>
        );
      })}
    </Group>
  );
}
