import { Link, useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Checkbox,
  Group,
  Input,
  MultiSelect,
  Text,
} from '@mantine/core';
import { type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import { AddressAutocomplete } from '@/components/AddressAutocomplete';
import type { Address, Carmen, Provider, Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  provider?: Provider;
  sites: Site[];
  carmen: Carmen;
  address: Address;
  api_key: string;
}

export function Form({ provider, sites, carmen, api_key, address }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, transform, errors } = useForm(provider);

  transform(({ provider_sites: providerSites, address: Address, ...data }) => ({
    provider_sites_attributes: providerSites,
    address_attributes: Address,
    ...data,
  }));

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      data.id != null ? 'patch' : 'post',
      `/provider/providers/${data.id != null ? data.id : ''}`,
    );
  };

  const handleAddressChange = (updatedAddress: Address) => {
    setData('address', updatedAddress);
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('site.plural')}>
            <MultiSelect
              required
              value={
                data.provider_sites != null
                  ? data.provider_sites
                      .filter(site => site._destroy == null)
                      .map(site => site.site_id.toString())
                  : []
              }
              hidePickedOptions
              onChange={value => {
                setData('provider_sites', [
                  ...(data.provider_sites?.reduce(
                    (sites, { _destroy, ...site }) => {
                      if (
                        value.includes(site.site_id.toString()) &&
                        _destroy != null
                      ) {
                        return [...sites, site];
                      } else if (
                        !value.includes(site.site_id.toString()) &&
                        site.id != null
                      ) {
                        return [...sites, { ...site, _destroy: true }];
                      } else if (!value.includes(site.site_id.toString())) {
                        return sites;
                      }
                      return [...sites, { ...site, _destroy }];
                    },
                    [],
                  ) ?? []),
                  ...(value.reduce((sites, siteId) => {
                    if (
                      data.provider_sites == null ||
                      !data.provider_sites?.some(
                        site => site.site_id.toString() === siteId,
                      )
                    ) {
                      return [...sites, { site_id: siteId }];
                    }
                    return sites;
                  }, []) ?? []),
                ]);
              }}
              data={sites?.map(site => ({
                value: site.id.toString(),
                label: site.description,
              }))}
            />
          </Field>
          <Field label={t('user.column.email')}>
            <Input
              required
              name="email"
              value={data.email != null ? data.email : ''}
              onChange={e => {
                setData('email', e.target.value);
              }}
              error={errors.email}
            />
          </Field>
          <Field label={t('user.column.first_name')}>
            <Input
              required
              name="first_name"
              value={data.first_name != null ? data.first_name : ''}
              onChange={e => {
                setData('first_name', e.target.value);
              }}
              error={errors.first_name}
            />
          </Field>
          <Field label={t('user.column.last_name')}>
            <Input
              required
              name="last_name"
              value={data.last_name != null ? data.last_name : ''}
              onChange={e => {
                setData('last_name', e.target.value);
              }}
              error={errors.last_name}
            />
          </Field>
          {data.id != null && (
            <Field label={t('client.column.invitation')}>
              {data.invitation_accepted_at !== null ? (
                <Text>
                  Accepted on {formatDate(data.invitation_accepted_at)}
                </Text>
              ) : (
                <>
                  {data.invitation_sent_at !== null && (
                    <Text>
                      Invitation sent on {formatDate(data.invitation_sent_at)}
                    </Text>
                  )}
                  <Button
                    component={Link}
                    as="button"
                    method="post"
                    href={`/provider/provider_invites?provider_id=${data.id}`}
                    onClick={e => {
                      if (
                        !confirm(
                          t('provider.confirm.resend_invite', {
                            name: `${data.first_name} ${data.last_name}`,
                          }),
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                  >
                    {t('user.action.resend_invitation')}
                  </Button>
                </>
              )}
            </Field>
          )}
          <Field label="Are you a practitioner?">
            <Checkbox
              name="role"
              checked={data.role === 'practitioner'}
              onChange={e => {
                setData(
                  'role',
                  e.target.checked ? 'practitioner' : 'administrator',
                );
              }}
              error={errors.role}
            />
          </Field>
          {data.role === 'practitioner' && (
            <>
              <Field label="Practitioner Number" required>
                <Input
                  required
                  name="practitioner_number"
                  value={
                    data.practitioner_number != null
                      ? data.practitioner_number
                      : ''
                  }
                  onChange={e => {
                    setData('practitioner_number', e.target.value);
                  }}
                  error={errors.practitioner_number}
                />
              </Field>
              <AddressAutocomplete
                address={address || {}}
                onAddressChange={handleAddressChange}
                carmen={carmen}
                api_key={api_key}
                errors={errors}
                errorPrefix="address."
                required={false}
              />
            </>
          )}
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Link href="/provider/providers">
              <Button color="black">{t('common.action.cancel')}</Button>
            </Link>
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
